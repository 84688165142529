<template>
  <!-- 引入样式 -->
  <div class="Detail">
    <shopHeader :shopId="shop_id" v-if='shop_type==1||!shop_type'></shopHeader>
    <shopHeaderSupplier :shopId="shop_id" v-else-if="shop_type==2"></shopHeaderSupplier>
    <shopHeader4s :shopId="shop_id" v-else-if='shop_type==3'></shopHeader4s>
   
    <vbar :shopId='shop_id'></vbar>
    <div class="content_container">

      <div class="topbar">
        <el-breadcrumb separator="/">
          <el-breadcrumb-item class="hover"><span @click="home"> 首页</span></el-breadcrumb-item>
          <template v-if="$route.query.catids">
            <el-breadcrumb-item v-if='subnav1.name' class="hover"><span @click="nav(subnav1.id)">{{subnav1.name}}</span></el-breadcrumb-item>
            <el-breadcrumb-item v-if='subnav2.name' class="hover"><span @click="nav(subnav2.id)">{{subnav2.name}} </span></el-breadcrumb-item>
          </template>
          <template v-else>
            <el-breadcrumb-item><span>搜索：{{$route.query.goodsname}}</span></el-breadcrumb-item>

          </template>
        </el-breadcrumb>
      </div>
      <div class="pro-list">
        <ol>
          <li class="item" v-for="(item, index) in goodList" :key="index">
            <div class="item-img-wraper">
              <el-image lazy class="item-img" :src="$config.baseUrl + item.files_path" @click="toDetail(item)" />
            </div>
            <p class="item-name" @click="toDetail(item)">
              {{ item.goods_name }}
            </p>
            <div class="item-price" v-if="item.isprice == 2">
              <span>
                ￥{{ parseFloat(item.min_price).toFixed(2) }} - ￥{{
                    parseFloat(item.max_price).toFixed(2)
                  }}
              </span>
            </div>
            <div class="item-price" v-if="item.isonly == 1 && item.isprice == 1">
              <span>￥{{ parseFloat(item.sales_price/ 100).toFixed(2)  }}</span>
            </div>
            <div class="item-price" v-if="item.isonly == 2 && item.isprice == 1">
              <span>￥{{ item.spec_min_price}}起</span>
            </div>

          </li>
        </ol>
      </div>

    </div>
  </div>
</template>
<script>
import vbar from "@/components/vbar";
import shopHeader4s from "@/components/shop-header-4s";
import shopHeader from "@/components/shop-header";
import shopHeaderSupplier from "@/components/shop-header-supplier";


export default {
  components: {
    shopHeader4s, shopHeader, shopHeaderSupplier,
    vbar
  },

  data() {
    return {
      shop_type:'',
      kefu_qq: '',
      subnav1: { name: "", id: "" },
      subnav2: { name: "", id: "" },
      classify_name1: "",
      classify_name2: "",
      shop_id: undefined,
      star: 2,
      shopSort: [],
      show1: false,

      searchData: {
        sale_num_pr: undefined,
        price_pr: undefined
      },

      num: 0,
      num2: 1,
      listIndex: 0,
      name: "",
      goodList: [],
      shopInfo: "",
      couponList: "",
      car: false,

      tipsQuantity: 0,

      display: false,
      catids: "",
      pid: ""
    };
  },
  watch: {
    "$route.query"() {

      this.searchData = Object.assign(this.searchData, this.$route.query);

      this.getGoods();

      this.link();
    },

    "$route.query.pid"() {
      this.searchData.shopid = this.shop_id;
      if (this.$route.query.pid) {
        this.searchData.pid =
          parseInt(this.$route.query.pid);

      }
      this.getGoods();

      this.link();
    },
    "$route.query.catids"() {
      this.searchData.shopid = this.shop_id;
      if (this.$route.query.catids) {
        this.searchData.catids =
          parseInt(this.$route.query.catids)
      }

      this.link();
    }
  },
  computed: {},
  mounted() {
    this.shop_id = parseInt(this.$route.query.shopid);

    this.searchData = Object.assign(this.searchData, this.$route.query);

    if (this.$route.query.shopid) {
      this.$get("seller/shopclassify", {
        shop_id: this.$route.query.shopid
      }).then(res => {
        this.shopSort = res;
        this.link();
      });
    }

    this.getShop();
  },
  methods: {
    nav(id) {
      this.searchData = {
        catids: id,
        shopid: this.shop_id
      };
      this.getGoods();
    },
    home() {
      this.$router.push({
        path: "/shop",
        query: { id: this.shop_id }
      });
    },
    link() {
      this.shopSort.forEach(item => {
        if (this.$route.query.pid != 0) {
          if (item.id == this.$route.query.pid) {
            this.subnav1.name = item.classify_name;
            this.subnav1.id = item.id;
            item.children.forEach(i => {
              if (i.id == this.$route.query.catids) {
                this.subnav2.name = i.classify_name;
                this.subnav2.id = i.id;
              }
            });
          }
        } else {
          if (item.id == this.$route.query.catids) {
            this.subnav1.name = item.classify_name;
            this.subnav1.id = item.id;
            this.subnav2.name = "";
            this.subnav2.id = "";
          }
        }
      });
    },
    //跳转详情页
    toDetail(item) {
      console.log("item", item);
      this.$router.push({
        path: "/detail",
        query: { id: item.goods_id, brand: item.get_brand || {}.name }
      });
    },
    searchAll() {
      this.num = 0;
      this.searchData.sale_num_pr = undefined;
      this.searchData.price_pr = undefined;
      this.getGoods();
    },
    searchSale() {
      this.num = 1;
      this.searchData.sale_num_pr = "desc";
      this.searchData.price_pr = undefined;
      this.getGoods();
    },

    searchPrice() {
      this.num = 2;
      this.searchData.sale_num_pr = undefined;
      this.searchData.price_pr = "asc";
      this.getGoods();
    },

    //获取全部产品
    getGoods() {
      this.$get("home/goods", this.searchData).then(res => {

        this.goodList = res.items.data;
        console.log(" this.goodList", this.goodList);
      });
    },

    //获取店铺信息
    getShop() {
      this.$get("seller/shop/" + this.$route.query.shopid).then(res => {
        this.shopInfo = res;
        this.kefu_qq = this.shopInfo.kefu_qq
         this.shop_type=this.shopInfo.shop_type
        this.searchData.shopid = res.shop_id;
        if (this.$route.query.catids) {
          this.searchData.catids =
            parseInt(this.$route.query.catids) || parseInt(this.$route.query.pid);
        }

        this.getGoods();
      });
    }
  },
  created() { }
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/mixins";
.pointer {
  background: #fe6925;

  color: #fff;
  border-radius: 5px;
}
.topbar {
  background: #fff;
  width: 100%;
  text-align: center;
  color: #757575;
  border-bottom: #ccc 1px solid;
  padding: 10px 20px;

  ul {
    padding-top: 10px;
    box-sizing: border-box;
    width: 100%;
    height: 50px;
    @include row;
    li {
      cursor: pointer;

      @include center;
      @include row;
      flex-wrap: nowrap;
      height: 30px;
      line-height: 30px;
      width: 80px;
      text-align: center;
      margin: 0 20px;
      @include center;
    }
  }
}
.Detail {
  background: #f5f5f5;
}

.banner {
  width: 100%;
  img {
    width: 100%;
    height: 350px;
  }
}
.product-info {
  background: #fff;
  padding: 20px;
  line-height: 25px;
  text-align: left;
  margin-bottom: 20px;
}

.pro-list {
  background: #fff;
  @include row;

  ul {
    background: #f0f0f0;
    width: 300px;

    li {
      padding: 10;
    }
  }
  ol {
    flex: 1;
    padding: 20px 10px;
    margin: 0 -10px;

    .item {
      background: #fff;
      box-sizing: border-box;
      margin: 0 10px;
      text-align: center;
      width: 220px;
      height: 300px;
      overflow: hidden;
      margin-bottom: 10px;
      float: left;
      position: relative;
      .item-img-wraper {
        overflow: hidden;

        height: 220px;

        box-sizing: border-box;

        .item-img {
          width: 100%;
          height: 100%;
        }
      }
      .shop_name {
        text-align: left;
        line-height: 30px;
        @include space-between;

        p {
          color: #999;
          width: 80%;
          @include ellipsis;
        }
      }
      .item-name {
        text-align: left;
        padding: 5px 0;
        font-size: 14px;
        color: #757575;
        line-height: 18px;
        overflow: hidden;
        height: 43px;
        word-break: break-all;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .item-price {
        font-size: 24px !important;
        @include space-between;
        line-height: 18px;
        span {
          font-size: 16px !important;
        }
        color: red;
      }
      .el-image {
        width: 100%;
        height: 220px;
      }
      img {
        width: 100%;
        height: 220px;
      }
    }
  }
  ol:after {
    content: "";
    width: 30%;
  }
}
</style>
 